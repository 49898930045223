import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import PanelHeader from '../../components/panelHeader/PanelHeader';
import BtnPrint from "../../components/BtnPrint/BtnPrint";
import SetContent from '../../utils/SetContent';
import { useHttp } from '../../hooks/http.hook';

import './_order.scss';
import attention from './../../resourses/icons/attention.svg';
const Order = () =>  {
  const {id} = useParams(); // тут будем получать id заказа, и по нему будем отправлять запрос

  const allergens = useSelector((state => state.matrix.allergens));
 
  const {request, process, setProcess, test} = useHttp();

  const weekdays = [
    {id: 0, name: 'Monday'},
    {id: 1, name: 'Tuesday'},
    {id: 2, name: 'Wednesday'},
    {id: 3, name: 'Thursday'},
    {id: 6, name: 'Sunday'},
  ]

  const currentDay = function () {
    let day = new Date().getDay();
     return day === 0 ? 6 : day - 1
  }()

  const [activeTab, setActiveTab] = useState(currentDay)
  const [showAlert, setShowAlert] = useState(false);
  const [order, setOrder] = useState({
    id: '',
    avatar: '',
    name: '',
    nickname: '',
    address: '',
    phone: '',
    deliveryTime: '0',
    allergens: ["0","0","1","0","0","0","1","0","1","0"],
    weekdays: [ 
      [],[],[],[],[],[],[]
    ]
  });  


  //отправляем запрос получния данных о заказе
  useEffect(() => { 
    if (test) {
      setProcess('confirmed');
      setOrder({
        id: '',
        avatar: '',
        name: 'john',
        nickname: 'tur',
        address: 'address',
        phone: '+999999999999',
        deliveryTime: '0',
        allergens: ["0","0","1","0","0","0","1","0","1","0"],
        weekdays: [ 
          // массив с днями недели, где каждый день - это массив с блюдами
          // ВАЖНО. Если для какого то дня нет блюд, должен придти пустой массив для конкретного дня.
          // т.е weekdays должен всегда содержать в себе 7 элементов массивов, где 0 - monday а 6 - sunday
          [
            {id: '1', img: 'https://www.w-dog.ru/wallpapers/4/19/484842159283230/vtorye-blyuda-ryba-kartofel-limon-lajm-ovoshhi-tarelka-eda-foto.jpg', name: 'Spaghetti Bolognese with meatbals', type: 'breakfest', kcal: '630', pro: '12', carb: '44', fat: '15', containAllergen: true, added: false},
            {id: '2', img: '', name: 'kebab', type: 'dinner', kcal: '230', pro: '25', carb: '44', fat: '10', containAllergen: true, added: true},
            {id: '3', img: '', name: 'kebab', type: 'dinner', kcal: '230', pro: '25', carb: '44', fat: '10', containAllergen: false, added: true},
            {id: '4', img: '', name: 'kebab', type: 'dinner', kcal: '230', pro: '25', carb: '44', fat: '10', containAllergen: false, added: true},

          ], // monday
          [
            {id: '2', img: '', name: 'taco', type: 'breakfest', kcal: '630', pro: '12', carb: '44', fat: '15', containAllergen: false, added: false},
            {id: '11', img: '', name: 'potato', type: 'dinner', kcal: '330', pro: '10', carb: '34', fat: '5', containAllergen: false, added: false},
          ], // thuesday
          [],[],[],[],[]
        ]
      })
    } else {
      request(undefined, {
        "command": '',
        "id" : id
      }).then((res)=>{
        setOrder(res)
        setProcess('confirmed');
      });
    }
   
  }, []);

  const changeDay = (dayId) => {
    setActiveTab(dayId);
  }

  const toggleDish = (id) => {
    setOrder(state => {
      const newState = structuredClone(state);
      let day = newState.weekdays[activeTab].map(dish => {
        if (dish.id === id) {
          return {...dish, added: !dish.added}
        } else {
          return dish
        }
      });
      newState.weekdays[activeTab] = day;
      return newState
    })
  }

  // отправка отмеченых блюд в заказе
  const onSave = () => {
    // формирование массива с отмеченными блюдами где ключ - это id блюда, а значение - это отмечено блюдо или нет.
    const addedArr = order.weekdays[activeTab].map(dish => {
      return {
        [dish.id]: dish.added
      }
    })
    request(undefined, {
      "command": '',
      "id" : id, // id заказа,
      "dishes": addedArr
    }).then((res) => {
      setOrder(res)
      setProcess('confirmed');
      setShowAlert(true)
    });
  }

  const renderOrder = () => {
    return (
      <div className="child-form">
        <div className="child-form__inner">
          <div className="child-form__left">
            <div className="child-form__avatar">
              <img src={order.avatar} alt="" />
            </div>
          </div>
          <div className="child-form__right">
            <div className="child-form__col-box">
              <div className="child-form__col">
                <label>
                  <span className="child-form__label-name">id</span>
                  <input type="text" name="id" value={order.id} readOnly/>
                </label>
                <label>
                  <span className="child-form__label-name">Name Surname Middle name</span>
                  <input type="text" name="name" value={order.name} readOnly/>
                </label>
                <label>
                  <span className="child-form__label-name">Phone</span>
                  <input type="text" name="name" value={order.phone} readOnly/>
                </label>
              </div>
              <div className="child-form__col">
                <label>
                  <span className="child-form__label-name">Nickname</span>
                  <input type="text" name="nickname" value={order.nickname} readOnly/>
                </label>
                <label>
                  <span className="child-form__label-name">Address</span>
                  <input type="text" name="address" value={order.address} readOnly/>
                </label>
                <label>
                  <span className="child-form__label-name">Delivery time</span>
                  <input type="text" name="address" value={order.deliveryTime === '0' ? '7 AM to 11 AM (Morning)' : '6 PM to 10 PM (the night before)'} readOnly/>
                </label>
              </div>
            </div>

            <div className="child-form__checkboxes">
              <span className="child-form__label-name">What do you not eat?</span>
                <div className="child-form__checkbox-list">
                  {
                    allergens.map((item, i) => {
                      // перебираем все аллергены и если в заказе приходит отмеченный аллерген - отрисовываем его
                      if (order.allergens[i] === '1') {
                        return (
                          <label className="checkbox-sm" key={i}>
                            <input className="sr-only"
                            type="checkbox"
                            name="allergens"
                            value={item}
                            checked={true}
                            />
                            <div></div>
                            <span>{item}</span>
                        </label>
                        )
                      }
                    })
                  }
              </div>
            </div>

            <div className="order__tabs tabs">
              {weekdays.map(day => {
                return  <button key={day.id}
                  className={`tabs__btn ${activeTab === day.id ? 'active' : ''}`}
                  onClick={() => changeDay(day.id)}>
                    {day.name}
                </button>
              })}
            </div>

            <ul className="order__dish-list">
              {order.weekdays[activeTab].map(dish => {
                const {id, img, name, type, kcal, pro, carb, fat, containAllergen, added} = dish;
                return (
                  <li key={id}
                    className={`order__dish-item ${activeTab === currentDay ? 'edit' : ''}`}
                    onClick={activeTab === currentDay ? () => toggleDish(id) : undefined}>
                    <div className="order__img-box">
                      <img className="order__img" src={img} alt="" />
                      { containAllergen ? 
                        <>
                          <img className="order__attention-icon" src={attention} />
                          <div className="order__attention">Contains an allergen</div>
                        </> : null }
                   
                    </div>
                    <div className="order__info">
                      <div className="order__check">
                        <div className={`status ${added ? 'ok': 'disable'}`}></div>
                        {added ? 'Added' : 'Not added'}
                      </div>
                      <div className="order__type">{type}</div>
                      <div className="order__name">{name}</div>
                      <div className="order__params">
                        <span>{kcal} kcal</span>
                        <span>{pro}g Pro</span>
                        <span>{carb}g Carb</span>
                        <span>{fat}g Fat</span>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
            {
              currentDay === activeTab ?
              <button className="order__save button" onClick={() => onSave()}>save</button> :
              null
            }
            
          </div>
        </div>

      </div>
    )
  }

  return (
    <>
      <PanelHeader title="Children">
        <BtnPrint/>
      </PanelHeader>

      <SetContent process={process} component={renderOrder()}/>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={"The changes are saved"}/>
    </>
  )
}

export default Order;