import React,  {useState, useEffect, useRef} from 'react';

import PanelHeader from "../../components/panelHeader/PanelHeader";
import SetContent from '../../utils/SetContent';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import { useHttp } from '../../hooks/http.hook';

import './_settings.scss';
const Settings = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState('');
  const [settings, setSettings] = useState({})

  const {request, process, setProcess, test} = useHttp();

  useEffect(() => {
    getSettings();
    
  }, [])

  // запрос на получение настроек
  const getSettings = () => {
    if (test) {
      // количество дней которое должно пройти что бы применились изменения адресов, планов питания и блюд
      setSettings({
        addressess: '3',
        packages: '3',
        dishes: '3'
      })
      setProcess('confirmed')
    } else {
      request(undefined, {
            command: 'settings.read_settings',
          })
          .then(res => {
            if (res.status === '1'){
              setProcess('confirmed')
              setSettings(res.message[0])
            } else {
              setTextAlert('Something went wrong');
              setShowAlert(true)
            }
          })
    }
  }

// запрос на сохранение настроек
  const onSave = () => {
    const { addressess, packages, dishes} = settings;
    if (test) {
      console.log(settings);
      setShowAlert(true)
      setTextAlert('The settings have been successfully saved')
     
    } else {
      request(undefined, {
            command: 'settings.update_settings',
            addressess,
            packages,
            dishes
          })
          .then(res => {
            console.log(res);
            if (res.status === '1'){
              setProcess('confirmed')
              setTextAlert('The settings have been successfully saved');
              setSettings(res.message[0])
            } else {
              setTextAlert('Something went wrong');
            }
            setShowAlert(true)
          })
    }
  }

  const handlerInput = (target) => {
    const { name, value } = target;
    const pattern = /^(?:[0-9]+|)$/;
    if(!pattern.test(value) || value > 15) {
        return
    }
    setSettings({...settings,  [name]: value})
  }

  const checkForm = () => {
    if (settings.addressess && settings.packages && settings.dishes) {
      return false
    }
    return true
  }

  const renderSettings = (
    <div className='settings__inner'>
          <label className='settings__label'>
            <span>Addresses</span>
            <input
              className={`input ${settings.addressess ? '' : 'input--error'}`}
              type='text'
              name='addressess'
              value={settings.addressess}
              onChange={(e) => handlerInput(e.target)}/>
          </label>
          <label className='settings__label'>
            <span>Packages</span>
            <input
              className={`input ${settings.packages ? '' : 'input--error'}`}
              type='text'
              name='packages'
              value={settings.packages}
              onChange={(e) => handlerInput(e.target)}/>
          </label>
          <label className='settings__label'>
            <span>Dishes</span>
            <input
              className={`input ${settings.dishes ? '' : 'input--error'}`}
              type='text'
              name='dishes'
              value={settings.dishes}
              onChange={(e) => handlerInput(e.target)}/>
          </label>
          <button className='button' onClick={onSave} disabled={checkForm()}>Save</button>
        </div>
  )

  return (
    <>
      <PanelHeader title='Settings'/>
      <div className='settings'>
        <SetContent process={process} component={renderSettings} />
      </div>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert}/>
    </>
  )
}

export default Settings;