import React from 'react';
import './_panelHeader.scss'
const PanelHeader = ({title, children}) => {
  return (
    <div className="panelhead">
      <h1 className="panelhead__title">{title}</h1>
      {children}
    </div>
  );
};



export default PanelHeader;