import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useHttp } from "../../hooks/http.hook";
import ModalAlert from "../ModalAlert/ModalAlert";

import './_dishForm.scss';




function DishForm({dish, setDish}) {
  const {id} = useParams();
  const form = useRef(0);
  const navigate = useNavigate();
  const {request, process, setProcess, test} = useHttp();

  const [showConfirm, setShowConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(null);

  const allergens = useSelector((state => state.matrix.allergens));
  const typeDishes = useSelector((state => state.matrix.typeDishes));

  const weekDays = ['Mon', 'Tue', 'Wen', 'Thu', 'Fri'];
  let alertConfirm

  //обработчик для инпутов с картинками
  const inputFileHandler = (e) => {
    const currFiles = e.target.files;
    if(currFiles.length > 0){
      let src = URL.createObjectURL(currFiles[0])
      setDish(state => ({...state, [e.target.name]: src}))
    }
  }

  //обработчик текстовых инпутов
  const handleChange = (e) => {
    setDish(state => ({...state, [e.target.name]: e.target.value}))
  }

  // обработчик дней недели и аллергенов
  const handlerMatrix = (e, i) => {
    setDish(state => {
      let  newMatrix = [...state[e.target.name]];
      newMatrix[i] =  newMatrix[i] === '1' ? '0' : '1';
      return {...state, [e.target.name]: newMatrix}
    })
  }
  const handlerMatrixRadio = (e, i) => {
    setDish(state => {
      let newMatrix = [...state[e.target.name]];
      newMatrix.forEach((item,i) => newMatrix[i] = '0')
      newMatrix[i] =  newMatrix[i] === '1' ? '0' : '1';
      return {...state, [e.target.name]: newMatrix}
    })
  }

  //отправка формы с запросом на редактирование
  const submitForm = async (e) => {
    e.preventDefault();
    if (test) {
      const body = new FormData(form.current);
      for (let [key, value] of body) {
        console.log(`${key} — ${value}`)
      }
    } else {
      const body = new FormData(form.current);
      body.delete('allergens');
      body.delete('days'); // удаляем все чекбоксы из формы
      body.delete('type'); // удаляем все чекбоксы из формы
      body.delete('ingredients'); // удаляем все чекбоксы из формы
      body.append('allergens', dish.allergens) // добавляем матрицу всех чекбоксов аллергенов
      body.append('days', dish.days) // добавляем матрицу всех чекбоксов дней
      body.append('type', dish.type) // добавляем матрицу всех чекбоксов типов блюд
      body.append('ingredients', dish.ingredients.split(','))
      body.append('id', id)
      body.append("command", 'dish.add_dish')
      for (let [key, value] of body) {
        console.log(`${key} — ${value}`)
      }
      request(undefined, body).then((res)=>{
        // получаем код ответа и в зависимости от него формируем текст сообщения
        if (res.responseCode === '1') {
          setTextAlert('The dish has been successfully created');
        }
        setProcess('confirmed'); 
        setShowAlert(true);
        });
    }
  }

    //отправка запроса на удаление блюда
  const onDelete = () => {
    if (test) {
      setTextAlert('The dish has been successfully deleted');
      setShowConfirm(false);
      setShowAlert(true)
      setProcess('confirmed');
    } else {
      request(undefined, {
        "command" : "dish.remove_dish",
        "id" : id
      }).then((res)=>{
        // получаем код ответа и в зависимости от него формируем текст сообщения
        if (res.status === '1') {
          setTextAlert('The dish has been successfully deleted');
          alertConfirm = () => navigate("/dish-list")
          // setAlertConfirm(() => navigate("/dish-list"))
        } else {
          setTextAlert('Something went wrong');
        }
        setShowConfirm(false);
        setShowAlert(true)
        setProcess('confirmed');
        });
    }
  }

  return (
    <div>
      <form className="dish-form" ref={form}>
          <div className="dish-form__box">
            <div className="dish-form__images">
              <label className={`dish-form__label-img ${dish.img1.slice(-1) === '/' ? 'empty' : ''}`}>
                <input className="sr-only" type="file" name="img1" accept="image" onChange={inputFileHandler}/>
                <img src={dish.img1} alt="" />
                <svg className="edit" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="#FF9D7D" strokeWidth="2"/>
                  <path d="M33.4254 22.986L34.3666 22.0448C35.1153 21.2961 35.5359 20.2807 35.5359 19.2218C35.5359 18.163 35.1153 17.1475 34.3666 16.3988C33.6179 15.6501 32.6024 15.2295 31.5436 15.2295C30.4848 15.2295 29.4693 15.6501 28.7206 16.3988L27.7794 17.34L19.1272 25.9902C18.5413 26.5771 18.2479 26.8705 17.9961 27.1933C17.6988 27.5743 17.4438 27.9866 17.2357 28.4228C17.06 28.7924 16.9291 29.1863 16.6671 29.9722L15.5564 33.3033M33.4254 22.986C33.4254 22.986 31.4263 22.8682 29.6618 21.1037C27.8972 19.3401 27.7804 17.34 27.7804 17.34M33.4254 22.986L24.7742 31.6362C24.1884 32.222 23.895 32.5155 23.5721 32.7673C23.1911 33.0646 22.7788 33.3196 22.3426 33.5277C21.973 33.7033 21.5801 33.8343 20.7933 34.0963L17.4621 35.207M17.4621 35.207L16.6478 35.4791C16.458 35.5427 16.2542 35.5522 16.0594 35.5064C15.8645 35.4605 15.6863 35.3613 15.5447 35.2197C15.4031 35.0782 15.3039 34.8999 15.2581 34.705C15.2122 34.5102 15.2217 34.3064 15.2853 34.1166L15.5574 33.3023L17.4621 35.207Z" stroke="#F55926" strokeWidth="1.5"/>
                </svg>
                <svg className="upload" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="50" width="50" height="50" rx="25" transform="rotate(90 50 0)" fill="#F55926"/>
                  <path d="M22.7888 17.5L27.2112 17.5L27.2112 22.7888L32.5 22.7888L32.5 27.2112L27.2112 27.2112L27.2112 32.5L22.7888 32.5L22.7888 27.2112L17.5 27.2112L17.5 22.7888L22.7888 22.7888L22.7888 17.5Z" fill="white"/>
                </svg>
              </label>
              <label className={`dish-form__label-img ${dish.img2.slice(-1) === '/' ? 'empty' : ''}`}>
                <input className="sr-only" type="file" name="img2" accept="image" onChange={inputFileHandler} />
                <img src={dish.img2} alt="" />
                <svg className="edit" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="#FF9D7D" strokeWidth="2"/>
                  <path d="M33.4254 22.986L34.3666 22.0448C35.1153 21.2961 35.5359 20.2807 35.5359 19.2218C35.5359 18.163 35.1153 17.1475 34.3666 16.3988C33.6179 15.6501 32.6024 15.2295 31.5436 15.2295C30.4848 15.2295 29.4693 15.6501 28.7206 16.3988L27.7794 17.34L19.1272 25.9902C18.5413 26.5771 18.2479 26.8705 17.9961 27.1933C17.6988 27.5743 17.4438 27.9866 17.2357 28.4228C17.06 28.7924 16.9291 29.1863 16.6671 29.9722L15.5564 33.3033M33.4254 22.986C33.4254 22.986 31.4263 22.8682 29.6618 21.1037C27.8972 19.3401 27.7804 17.34 27.7804 17.34M33.4254 22.986L24.7742 31.6362C24.1884 32.222 23.895 32.5155 23.5721 32.7673C23.1911 33.0646 22.7788 33.3196 22.3426 33.5277C21.973 33.7033 21.5801 33.8343 20.7933 34.0963L17.4621 35.207M17.4621 35.207L16.6478 35.4791C16.458 35.5427 16.2542 35.5522 16.0594 35.5064C15.8645 35.4605 15.6863 35.3613 15.5447 35.2197C15.4031 35.0782 15.3039 34.8999 15.2581 34.705C15.2122 34.5102 15.2217 34.3064 15.2853 34.1166L15.5574 33.3023L17.4621 35.207Z" stroke="#F55926" strokeWidth="1.5"/>
                </svg>
                <svg className="upload" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="50" width="50" height="50" rx="25" transform="rotate(90 50 0)" fill="#F55926"/>
                  <path d="M22.7888 17.5L27.2112 17.5L27.2112 22.7888L32.5 22.7888L32.5 27.2112L27.2112 27.2112L27.2112 32.5L22.7888 32.5L22.7888 27.2112L17.5 27.2112L17.5 22.7888L22.7888 22.7888L22.7888 17.5Z" fill="white"/>
                </svg>
              </label>
              <label className={`dish-form__label-img ${dish.img3.slice(-1) === '/' ? 'empty' : ''}`}>
                <input className="sr-only" type="file" name="img3" accept="image" onChange={inputFileHandler} />
                <img src={dish.img3} alt="" />
                <svg className="edit" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="48" height="48" rx="24" fill="white" stroke="#FF9D7D" strokeWidth="2"/>
                  <path d="M33.4254 22.986L34.3666 22.0448C35.1153 21.2961 35.5359 20.2807 35.5359 19.2218C35.5359 18.163 35.1153 17.1475 34.3666 16.3988C33.6179 15.6501 32.6024 15.2295 31.5436 15.2295C30.4848 15.2295 29.4693 15.6501 28.7206 16.3988L27.7794 17.34L19.1272 25.9902C18.5413 26.5771 18.2479 26.8705 17.9961 27.1933C17.6988 27.5743 17.4438 27.9866 17.2357 28.4228C17.06 28.7924 16.9291 29.1863 16.6671 29.9722L15.5564 33.3033M33.4254 22.986C33.4254 22.986 31.4263 22.8682 29.6618 21.1037C27.8972 19.3401 27.7804 17.34 27.7804 17.34M33.4254 22.986L24.7742 31.6362C24.1884 32.222 23.895 32.5155 23.5721 32.7673C23.1911 33.0646 22.7788 33.3196 22.3426 33.5277C21.973 33.7033 21.5801 33.8343 20.7933 34.0963L17.4621 35.207M17.4621 35.207L16.6478 35.4791C16.458 35.5427 16.2542 35.5522 16.0594 35.5064C15.8645 35.4605 15.6863 35.3613 15.5447 35.2197C15.4031 35.0782 15.3039 34.8999 15.2581 34.705C15.2122 34.5102 15.2217 34.3064 15.2853 34.1166L15.5574 33.3023L17.4621 35.207Z" stroke="#F55926" strokeWidth="1.5"/>
                </svg>
                <svg className="upload" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="50" width="50" height="50" rx="25" transform="rotate(90 50 0)" fill="#F55926"/>
                  <path d="M22.7888 17.5L27.2112 17.5L27.2112 22.7888L32.5 22.7888L32.5 27.2112L27.2112 27.2112L27.2112 32.5L22.7888 32.5L22.7888 27.2112L17.5 27.2112L17.5 22.7888L22.7888 22.7888L22.7888 17.5Z" fill="white"/>
                </svg>
              </label>
            </div>
            <div className="dish-form__info">
              <div className="dish-form__subtitle">Name dish</div>
              
              <div className="dish-form__type">
              {
                typeDishes.map((item, i) => {

                  return (
                    <label className="dish-form__checkbox" key={i}>
                      <input className="sr-only"
                      type="radio"
                      name="type"
                      value={item}
                      checked={dish.type[i] === '1'}
                      onChange={(e) => handlerMatrixRadio(e, i)} />
                      <span>{item}</span>
                  </label>
                  )
                })
              }
              </div>
              <input className="dish-form__input" type="text" placeholder="name dish" name="name" value={dish.name} onChange={handleChange}/>
              <input className="dish-form__input dish-form__input--ar" type="text" placeholder="name dish ar" name="arabian" value={dish.arabian} onChange={handleChange}/>
              <input className="dish-form__input" type="text" placeholder="ingredients" name="ingredients" value={dish.ingredients} onChange={handleChange}/>

              <div className="dish-form__input-box">
                <label>
                  <span className="mask">kcal</span>
                  <input type="text" name="kcal" maxLength={4} value={dish.kcal} onChange={handleChange}/>
                </label>
                <label>
                  <span className="mask">g/Pro</span>
                  <input type="text" name="proteins" maxLength={3} value={dish.proteins}onChange={handleChange}/>
                </label>
                <label>
                  <span className="mask">g/Carb</span>
                  <input type="text" name="carbs" maxLength={3} value={dish.carbs} onChange={handleChange}/>
                </label>
                <label>
                  <span className="mask">g/Fat</span>
                  <input type="text" name="fats" maxLength={3} value={dish.fats} onChange={handleChange}/>
                </label>
              </div>
            </div>
          </div>

          <div className="dish-form__box">
            <div className="dish-form__subtitle">Allergens</div>
            <div className="dish-form__checkboxes">
            {
              allergens.map((item, i) => {
                return (
                  <label className="dish-form__checkbox" key={i}>
                    <input className="sr-only"
                    type="checkbox"
                    name="allergens"
                    value={item}
                    checked={dish.allergens[i] === '1'}
                    onChange={(e) => handlerMatrix(e, i)} />
                    <span>{item}</span>
                </label>
                )
              })
            }
            </div>
            <div className="dish-form__subtitle dish-form__subtitle--week">Week Days</div>
            <div className="dish-form__checkboxes">
            {
              weekDays.map((item, i) => {

                return (
                  <label className="dish-form__checkbox" key={i}>
                    <input className="sr-only"
                    type="checkbox"
                    name="days"
                    value={item}
                    checked={dish.days[i] === '1'}
                    onChange={(e) => handlerMatrix(e, i)} />
                    <span>{item}</span>
                </label>
                )
              })
            }
            </div>
          </div>

          

          <div className="dish-form__btns">
            <button className={`dish-form__delete button ${id ? '' : 'hide'}`} type="button" onClick={() => {setShowConfirm(true)}}>Delete</button>
            <button className="dish-form__save button" onClick={(e) => submitForm(e)}>Save</button>
          </div>
      </form>

      <div className={`confirm-del ${showConfirm ? 'show': ''}`}>
        <div className="confirm-del__inner">
          <div className="confirm-del__title">{dish.type}</div>
          <div className="confirm-del__subtitle">{dish.name}</div>
          <div className="confirm-del__btns">
            <button className="confirm-del__delete button" onClick={onDelete}>Delete</button>
            <button className="confirm-del__cancel button"onClick={() => setShowConfirm(false)}>Cancel</button>
          </div>
        </div>
      </div>

      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert} alertConfirm={alertConfirm} />
    </div>
  );
}

export default DishForm;
