import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link} from 'react-router-dom';
import PanelHeader from '../../components/panelHeader/PanelHeader';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { useHttp } from '../../hooks/http.hook';

import './_editParent.scss'

import creditCard from '../../resourses/icons/credit-card.svg'
import applePay from '../../resourses/icons/apple-pay.svg'
import tabby from '../../resourses/icons/tabby.svg'
import tamara from '../../resourses/icons/tamara.svg'
import cash from '../../resourses/icons/cash.svg'

const EditParent = () => {
  const form = useRef(0);
  const {id} = useParams(); // тут будем получать id ребенка, и по нему будем отправлять запрос
  const navigate = useNavigate();
 
  const {request, process, setProcess, test} = useHttp();

  const paymentMethods = ['Credit Card', 'Apple pay', 'Tabby', 'Tamara', 'cash payment']
  const paymentIcons = [creditCard, applePay, tabby, tamara, cash]
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [targetConfirm, setTargetConfirm] = useState({
      id: null,
      name: null,
  });

  const [parent, setParent] = useState({
    id: '',
    avatar: '',
    name: '',
    dateBirth: '',
    gender: '',
    years: '',
    phone: '',
    address: '',
    email: '',
    paymentMethod: [],
    children: []
  }); 



  //отправляем запрос получния данных о родителе
  useEffect(() => { 
    if (test) {
      
      setParent({
        id: '',
        avatar: '',
        name: '',
        dateBirth: '',
        gender: '',
        years: '',
        phone: '',
        address: '',
        email: '',
        paymentMethod: [],
        children: [
          {id: '1',avatar: 'https://vip-1gl.ru/vipberrrt/10423beautiful_scenery_wallpaper.jpg', name: 'ahmad', age: '7'},
          {id: '2',avatar: 'https://vip-1gl.ru/vipberrrt/10423beautiful_scenery_wallpaper.jpg', name: 'ahmad', age: '7'},
          {id: '3',avatar: 'https://vip-1gl.ru/vipberrrt/10423beautiful_scenery_wallpaper.jpg', name: 'ahmad', age: '7'}
      ]
      })
    } else {
      request(undefined, {
        "command": "",
        "id" : id
      }).then((res)=>{
        setParent(res)
        setProcess('confirmed');
      });
    }
   
  }, []);

//обработчик для инпутов с картинками
  const inputFileHandler = (e) => {
    const currFiles = e.target.files;
    if(currFiles.length > 0){
      let src = URL.createObjectURL(currFiles[0])
      setParent(state => ({...state, [e.target.name]: src}))
    }
  }

//обработчик текстовых инпутов
  const handleChange = (e) => {
    setParent(state => ({...state, [e.target.name]: e.target.value}))
  }

   // обработчик дней недели и аллергенов
   const handleMatrix = (e, i) => {
    setParent(state => {
      let  newMatrix = [...state[e.target.name]];
      newMatrix[i] =  newMatrix[i] === '1' ? '0' : '1';
      return {...state, [e.target.name]: newMatrix}
    })
  }

  //отправка формы с запросом на редактирование
  const submitForm = async (e) => {
    e.preventDefault();
    if (test) {
      const body = new FormData(form.current);
      body.delete('paymentMethod');
      body.append('paymentMethod', parent.paymentMethod) // добавляем матрицу всех чекбоксов методов оплаты
  
      for (let [key, value] of body) {
        console.log(`${key} — ${value}`)
      }

    } else {
      const body = new FormData(form.current);
      body.delete('paymentMethod');
      body.append('paymentMethod', parent.paymentMethod)  // добавляем матрицу всех чекбоксов методов оплаты
      body.append('command', '')
      request(undefined, body).then((res)=>{
        // получаем код ответа и в зависимости от него формируем текст сообщения
        if (res.responseCode === '1') {
          setShowAlert(true)
          setTextAlert('Parent has been successfully saved')
        }
        setProcess('confirmed'); 
        });
    }
  }

  // запрос на удаление родителя
  const removeParent = (id) => {
    request(undefined, {
        "command": "",
        "id": id
    })
      .then(res => {
          setProcess('confirmed');
          setShowConfirm(false);
          navigate('/parents')
      })
  }

  // показываем окно подтверждения
  const onConfirmDelete = (id, name) => {
    setShowConfirm(true);
    setTargetConfirm({id, name})
  }

  return (
    <>
      <PanelHeader title="Parent" />
      <form className='parent-form' ref={form}>
        <div className='parent-form__inner'>
          <div className="parent-form__left">
            <label className='parent-form__avatar'>
              <input className="sr-only" type="file" name="avatar" accept="image" onChange={inputFileHandler} />
              <img src={parent.avatar} alt="" />
              <div className="parent-form__plus"></div>
            </label>
          </div>
          <div className="parent-form__right">
            <div className="parent-form__col-box">
              <div className="parent-form__col">
                <label>
                  <span className='parent-form__label-name'>id</span>
                  <input type="text" name='id' value={parent.id} onChange={handleChange}/>
                </label>
                <div className="parent-form__labels-box parent-form__labels-box--gender">
                  <label>
                    <span className='parent-form__label-name'>Date of birth</span>
                    <input type="text" name='dateBirth' value={parent.dateBirth} onChange={handleChange}/>
                  </label>
                  <label className="checkbox">
                      <input className="sr-only"
                      type="radio"
                      name="gender"
                      value='1'
                      checked={parent.gender == 1}
                      onChange={handleChange}/>
                      <span>male</span>
                  </label>
                  <label className="checkbox">
                      <input className="sr-only"
                      type="radio"
                      name="gender"
                      value='0'
                      checked={parent.gender == 0}
                      onChange={handleChange}/>
                      <span>female</span>
                  </label>
                </div>

                <label>
                  <span className='parent-form__label-name'>Phone</span>
                  <input type="text" name='phone' value={parent.phone} onChange={handleChange}/>
                </label>

                <label>
                  <span className='parent-form__label-name'>Address</span>
                  <input type="text" name='address' value={parent.address} onChange={handleChange}/>
                </label>

              </div>
              <div className="parent-form__col">

              <label>
                  <span className='parent-form__label-name'>Name Surname Middle name</span>
                  <input type="text" name='name' value={parent.name} onChange={handleChange}/>
                </label>

                <div className="parent-form__labels-box">
                  <label>
                    <span className='parent-form__label-name'>Years</span>
                    <input type="text" name='years' value={parent.years} onChange={handleChange}/>
                  </label>
                  
                  </div>
                  
                  <label>
                    <span className='parent-form__label-name'>E-Mail</span>
                    <input type="text" name='email' value={parent.email} onChange={handleChange}/>
                  </label>
              </div>
            </div>
          
            <div className="parent-form__row">
              <div className="parent-form__subtitle">Select Payment method</div>

              <div className="parent-form__payments">
              {
                   paymentMethods.map((item, i) => {
                    return (
                      <label className="parent-form__payments-label" key={i}>
                        <span>{item}</span>
                        <input className="sr-only"
                        type="radio"
                        name="paymentMethod"
                        value={item}
                        checked={parent.paymentMethod[i] == 1}
                          onChange={(e) => handleMatrix(e, i)} />
                        <div>
                          <img src={paymentIcons[i]} alt="" />
                        </div>
                        
                    </label>
                    )
                  })
                }
              </div>
            </div>

            <div className="parent-form__row">
              <div className="parent-form__subtitle">Children</div>
              <div className="parent-form__children">
                {parent.children.map((item, i) => {
                  return (
                    <div className="parent-form__child">
                      <img src={item.avatar} />
                      <div className="parent-form__child-name">{item.name}</div>
                      <div className="parent-form__child-age">{item.age} years</div>
                      <Link to={`/edit-child/${item.id}`}>Edit</Link>
                    </div>
                  )
                })}
              
              </div>
            </div>


          </div>
        </div>
        <div className="parent-form__btns">
          <button className='button button--red' type='button' onClick={() => onConfirmDelete(parent.id, parent.name)}>Delete</button>
          <button className='button' onClick={submitForm}>Save</button>
        </div>
      </form>
      <ModalAlert showAlert={showAlert} setShowAlert={setShowAlert} message={textAlert} />
      <ConfirmModal question='Delete parent?' text1={targetConfirm.name} showConfirm={showConfirm} setShowConfirm={setShowConfirm} actionConfirmed={() => removeParent(targetConfirm.id)}/>
    </>
  )
}

export default EditParent;