import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import DishForm from '../../components/DishForm/DishForm';
import PanelHeader from '../../components/panelHeader/PanelHeader';

function CreateDish() {

  const allergens = useSelector(state => state.matrix.allergens);

  const [dish, setDish] = useState({
    name: '',
    arabian: '',
    type: '',
    kcal: '',
    carbs: '',
    fats: '',
    proteins: '',
    img1: '',
    img2: '',
    img3: '',
    allergens: allergens.map( item => 0),
    days: [0,0,0,0,0]
  }); // параметры блюда, 


  return (
    <>
      <PanelHeader title="Dish"/>
      <DishForm dish={dish} setDish={setDish} />
    </>
  )
}

export default CreateDish;